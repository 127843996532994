<template>
    <div class="modal workout_notes" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Notes<span>{{ filter }}</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="notes_list">
                        <div class="search_area">
                            <input type="text" v-model="params.search" placeholder="Search by name, text etc...">
                            <button class="search_btn"><i class="fas fa-search"></i></button>
                        </div>
                        <ul>
                            <li v-for="(note, n) of notes.data" :key="n">
                                <label>{{ moment(note.created_at).format('ll') }}</label>
                                <div class="note">
                                    <div class="user">
                                        <img :src="note.contact ? note.contact.photo : ''" alt="">
                                        <div class="user_info">{{ note.contact.name }} <span>{{ note.contact.email }}</span></div>
                                    </div>
                                    <q>{{ note.notes }}</q>
                                    <textarea placeholder="Reply with notes.." v-model="note.reply"></textarea>
                                </div>
                                <div class="action_area">
                                    <span class="timeline" v-if="note.is_replied">Replied on {{ moment(note.replied_at).format('ll') }}</span>
                                    <span class="reply_btn" v-if="!note.is_replied" @click="handleNoteReply($event)">Reply <i class="fas fa-long-arrow-alt-right"></i></span>
                                    <span class="cancel_btn" @click="handleNoteReply($event)">Cancel</span>
                                    <span class="reply_btn send" @click="handleNoteReply($event, note, n)">Send via Portal Chat <i class="fas fa-long-arrow-alt-right"></i></span>
                                </div>
                            </li>
                        </ul>
                        <div class="table_footer">
                            <ul>
                                <li>{{ notes.from ? notes.from : 0 }} - {{ notes.to ? notes.to : 0 }} of <span>{{ notes.total ? notes.total : 0 }}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="model_pagination pb-4 mt-4">
                        <div class="pagination" v-show="notes.total">
                            <pagination v-model="params.page" :range-size="0" :pages="notes.last_page" @update:modelValue="handlePagination" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment-timezone'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'workout-notes',

        data () {
            return {
                params: {
                    workout_id: 0,
                    page: 1,
                    per_page: 5,
                    search: '',
                },
                moment
            };
        },

        props: {
            modelValue: Boolean,
            workout: Object,
            filter: String,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.params.workout_id    = vm.workout.id;
                    vm.params.page          = 1;

                    vm.getWorkoutNotes(vm.params);
                }
            }
        },

        computed: mapState({
            notes: state => state.workoutModule.workoutNotes,
            notesLoader: state => state.workoutModule.workoutNotesLoader,
        }),

        methods: {
            ...mapActions({
                getWorkoutNotes: 'workoutModule/getWorkoutNotes',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handlePagination () {
                const vm = this;

                vm.getWorkoutNotes(vm.params);
            },

            handleNoteReply (ev, note, number) {
                let el = ev.currentTarget.closest('li');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }

                if (number && number >= 0) {
                    const options = Helper.swalWarningOptions('Coming Soon!');

                    Swal.fire(options);
                }
            },
        },
    }
</script>

<style scoped>
.modal.workout_notes .modal_body {
    padding-top: 15px;
    background: #fff;
}

.notes_list .search_area {
    background: #f9f9f9;
    border-radius: 20px;
    padding-right: 25px;
    position: relative;
    margin-bottom: 20px;
}

.notes_list .search_area input {
    height: 32px;
    width: 100%;
    background: transparent;
    padding: 0 15px;
    font-size: 11px;
    font-weight: 400;
    color: #5a5a5a;
}

.notes_list .search_area .search_btn {
    position: absolute;
    right: 3px;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 11px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.notes_list ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
}

.notes_list ul li {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.notes_list ul li label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
}

.notes_list ul li .note {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px;
    line-height: 0;
}

.notes_list ul li .note .user {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
}

.notes_list ul li .note .user img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.notes_list ul li .note .user_info {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.notes_list ul li .note .user_info span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.notes_list ul li .note q {
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    font-style: italic;
}

.notes_list ul li .note q:before,
.notes_list ul li .note q:after {
    font-size: 15px;
}

.notes_list ul li .note textarea {
    width: 100%;
    max-height: 0;
    height: 100px;
    overflow: hidden;
    padding: 0 15px;
    border-radius: 5px;
    background: #fff;
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    resize: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.notes_list ul li .action_area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.notes_list ul li .timeline {
    font-size: 11px;
    line-height: 14px;
    color: #999;
    font-weight: 400;
    font-style: italic;
    margin-right: auto;
}

.notes_list ul li .cancel_btn {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    cursor: pointer;
    padding-right: 10px;
    border-right: 1px solid #d9d9d9;
    display: none;
}

.notes_list ul li .reply_btn {
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.notes_list ul li .reply_btn.send {
    display: none;
}

.notes_list ul li.active .note textarea {
    max-height: 100px;
    padding: 10px 15px;
    margin: 10px 0 0 0;
}

.notes_list ul li.active .reply_btn:not(.send) {
    display: none;
}

.notes_list ul li.active .reply_btn.send,
.notes_list ul li.active .cancel_btn {
    display: block;
}

.notes_list .table_footer ul {
    flex-direction: inherit;
}

.notes_list .table_footer ul li {
    display: block;
}
</style>